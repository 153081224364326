<template>
  <v-col cols="12" sm="12" md="12" style="">
    <v-row class="ma-0 pa-0 campusList" >
      <v-col cols="12" sm="12" md="12" class="oneList pa-0" style="align-items: center;max-height: 140px">
        <v-row class="ma-0 pa-0" style="height: 100%">
          <v-col cols="12" sm="9" md="9" style="padding:0 12px;height:100%" >
            <v-row class="ma-0 pa-0" style="align-items: center;height: 100%;">
              <v-col cols="12" sm="8" md="7" class="img-container pa-0" style="height: 100%">
                <newImg :fileUrl="'files/'+ data.listLogo" style="width:100%;height:100%;object-fit: cover;margin: autos"></newImg>
                <!-- <img :src="'files/'+ this.data.listLogo" alt="" style="width:100%;height:100%;object-fit: cover;margin: autos"> -->
              </v-col>
              <v-col cols="12" sm="4" md="5" class="imgInfo campusName" >
                <span style="font-weight: bold;font-size: 1.5rem;padding-left: 20px;" :id="data.name">{{ data.name }}</span>
              </v-col>
            </v-row>
            <!--<div class="img-container" >-->

            <!--<div class="img-container2">-->
            <!--<div class="img-container3">-->
            <!---->
            <!--&lt;!&ndash;<div class="imgText">{{data.name}}</div>&ndash;&gt;-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="campusName" style="width:70%">-->
            <!---->
            <!--</div>-->
          </v-col>
          <v-col cols="12" sm="3" md="3" class="btnContent">
            <div class="goBtn x-btn" tabindex="0" role="button" @click="go" :aria-label="data.name" style="font-size: 25px">></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>

  <!--<div class="campusList">-->
  <!--  <div class="oneList">-->
  <!--    <div class="imgInfo" style="width:80%;overflow: hidden">-->
  <!--      <div class="img-container" style="width:30%">-->
  <!--        <img :src="'files/'+data.listLogo" alt="">-->
  <!--      </div>-->
  <!--      <div class="campusName" style="width:70%">-->
  <!--        <span>{{ data.name }}</span>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="btnContent" style="width:20%">-->
  <!--      <div class="goBtn">go</div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--</div>-->
</template>

<script>
import {mapMutations} from 'vuex'
import axios from "axios";
import newImg from "@/views/dashboard/componentsHa/img";
export default {
  name: "campusList",
  props: {
    data: {
      default: Object
    }
  },
  components: {
    newImg
  },
  data() {
    return {
      img: require("@/assets/images/test/desk.png")
    }
  },
  methods: {
    ...mapMutations('campusName',["setCampusName"]),
    go(){
      // this.setCampusName(this.data.name);
      this.$emit("go",this.data);
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.campusList {
  .oneList {
    box-sizing: border-box;
    min-height: 3rem;
    padding:10px;
    //padding: 1rem;
    display: flex;
    justify-content: space-between;
    .imgInfo {
      display: flex;
      justify-content: flex-start;
      .img-container{
        position: relative;
        .img-container2{
          position:absolute;
          top:50%;
          transform: translate(0,-50%);
          .img-container3{
            position: relative;
            width:100%;
            img{
              width:100%;
            }
            .imgText{
              background: rgba(10, 10, 10, 0.7);
              width: 100%;
              color: #fff;
              position: absolute;
              bottom: 0;
              transform: translate(0%, -33%);
            }
          }
        }
      }
      .campusName {
        font-weight: bold;
        line-height: 8rem;
        padding-left: 2rem;
      }
    }
    .btnContent {
      display: flex;
      justify-content: flex-end;
      .goBtn {
        //background: $btn-color;
        //background: #EE2C54;
        height: 4rem;
        width: 4rem;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 4rem;
        margin: auto;
        margin-right: 1rem;
        cursor: pointer;
      }
    }
  }
}
</style>
<!--<style scoped lang="scss">-->
<!--.campusList {-->
<!--  .oneList {-->
<!--    box-sizing: border-box;-->
<!--    height: 10rem;-->
<!--    padding-left: 1rem;-->
<!--    display: flex;-->
<!--    justify-content: space-between;-->
<!--    .imgInfo {-->
<!--      display: flex;-->
<!--      justify-content: flex-start;-->
<!--      .img-container{-->
<!--        position: relative;-->
<!--        img{-->
<!--          width:100%;-->
<!--          position: absolute;-->
<!--          top:50%;-->
<!--          transform: translate(0%,-50%);-->
<!--        }-->
<!--        .imgText{-->
<!--          background: rgba(10,10,10,0.7);-->
<!--          width:100%;-->
<!--          color: #fff;-->
<!--        }-->
<!--      }-->
<!--      .campusName {-->
<!--        font-weight: bold;-->
<!--        line-height: 10rem;-->
<!--        padding-left: 2rem;-->
<!--      }-->
<!--    }-->
<!--    .btnContent {-->
<!--      display: flex;-->
<!--      justify-content: flex-end;-->
<!--      .goBtn {-->
<!--        background: #EE2C54;-->
<!--        height: 4rem;-->
<!--        width: 4rem;-->
<!--        color: #fff;-->
<!--        border-radius: 50%;-->
<!--        text-align: center;-->
<!--        line-height: 4rem;-->
<!--        margin-top: 3rem;-->
<!--        margin-right: 1rem;-->
<!--        cursor: pointer;-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->

